/* Button Styles */

.button,
a.button.mat-mdc-button {
  display: inline-block;
  line-height: 32px;
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  border: none;

  // SIZES
  &.button-small {
    font-size: 12px;
    line-height: 24px;
    padding: 0px 8px;
  }

  &.button-large {
    font-size: 15px;
    line-height: 48px;
    padding: 0px 24px;
  }

  &.button-x-large {
    font-size: 16px;
    line-height: 56px;
    padding: 0px 24px;
  }


  // COLORS

  &.button-secondary {
    background: $mediumgray;
    color: rgba($white, .87);
  }

  &.button-plain {
    background: $white;
    color: rgba($darkgray, .87);
  }

  &.button-subtle {
    background: $mediumgray;
    color: darken($offwhite, 10%);
  }

  &.button-navy {
    background: $shrimp;
    color: rgba($white, .87);
  }

  &.button-banner {
    background: $darkgray;
    color: rgba($white, .87);
  }

  // &.button-shield,
  // &.button-shield.mat-button {
  //   background-color: $pink;
  //   background: $pink url('assets/images/logos/angular/angular_whiteTransparent.svg') 24px 13px no-repeat;
  //   color: rgba($white, .87);
  //   padding-left: 54px;
  //   background-size: 22px 22px;

  //   @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  //     background: $pink url('assets/images/logos/angular/angular_whiteTransparent.svg') 24px 13px no-repeat;
  //     background-size: 22px 22px;

  //   }
  // }
}

.cta-bar {
  text-align: center;

  .button {
    margin: 0px 8px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(1.1);
        color: $offwhite;
    }
  }
}

a.filter-button {
  width: 140px;
  font-size: 14px;
  padding: 0px 16px;
  margin: 8px;
  line-height: 48px;
  border: 2px solid $shrimp;
  border-radius: 4px;

  &:hover {
    background-color: $shrimp;
    color: white;
  }
}

[mat-button], [mat-raised-button], [mat-button], [mat-raised-button] {
  text-transform: uppercase;
}
