aio-contributor-list {
  @media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
    .grid-fluid{
      width: auto;
    }
  }

  @media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
    .grid-fluid{
      margin-left: 20px;
      margin-right: 20px;
      float: none;
      display: block;
      width: auto;
    }
  }
}

.group-buttons {
  margin: 32px auto;

  a {
    &.selected {
      background-color: $shrimp;
      color: white;
    }
  }
}

.contributor-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

aio-contributor {
  background: $white;
  margin: 8px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  transition: all .3s;
  perspective: 800px;

  &:hover {
    transform: translate3d(0,-3px,0);
    box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);

    .contributor-image {
      transform: scale(1.05);
    }

    .contributor-info {
      opacity: 1;
    }
  }

  .contributor-info {
    background: rgba($darkgray, 0.5);
    height: 168px;
    width: 168px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    border-radius: 50%;

    [mat-button] {
      color: $white;
      font-size: 14px;
      font-weight: 500;
      margin: 8px;
      padding: 0;

      &:hover {
        color: $lightgray;
      }

      &.icon {
        min-width: 20px;
        width: 20px;

        .fa-2x {
          font-size: 20px;
        }
      }
    }
  }

  div.contributor-card {
    width: 250px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    transform-style:preserve-3d;
    transition:transform ease 500ms;

    h3 {
      margin: 8px 0;
    }

    .card-front, .card-back {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }

    .card-front {
      justify-content: center;
    }

    .card-back {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px 24px;
      transform:rotateY(180deg);

      section {
        display: none;
      }

      p {
        margin: 8px 0;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
      }

    }

    &.flipped {
      transform:rotateY(180deg);
      .card-front {
        display: none;
      }
    }
  }

  .contributor-image {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    height: 168px;
    width: 168px;
    background-size: cover;
    background-position: center;
    margin: 8px auto;
    border: 2px solid $lightgray;
    transition: all .2s ease-in-out;
  }

  section {
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  p {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    margin: 8px 16px;
    text-overflow: ellipsis;
    overflow: scroll;
    font-weight: 400;
  }
}
