aio-shell.page-docs {
  .sidenav-content {
    // padding: 6rem 3rem 3rem 3rem; // THIS CAUSES THE TOP NAV TOOLBAR TO JUMP BETWEEN DOCS AND OTHER PAGES
    margin: auto;
  }
}

.sidenav-content {
  min-height: 100vh;
  padding: 80px 3rem 1rem;
}

@media (max-width: 600px) {
  aio-menu {
    display: none;
  }

  .sidenav-content {
    min-height: 450px;
    padding: 80px 1rem 1rem;
  }
}

.sidenav-container {
  width: 100%;
  height: 100vh;
}

.sidenav-content button {
  min-width: 50px;
}

#guide-change-log h2::before {
  content: "";
  display: block;
  height: 1px;
  margin: 24px 0px;
  background: $lightgray;
}
