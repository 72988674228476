.alert {
    padding: 16px;
    margin: 24px 0px;
    font-size: 14px;
    color: $darkgray;
    width: 100%;
    box-sizing: border-box;

    &.is-critical {
        border-left: 10px solid $shrimp;
        background-color: rgba($shrimp, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $shrimp;
        }
    }

    &.is-important {
        border-left: 10px solid $orange;
        background-color: rgba($orange, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $orange;
        }
    }

    &.is-helpful {
        border-left: 10px solid $shrimp;
        background-color: rgba($shrimp, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $shrimp;
        }
    }

    /* --- warning for archive version --- */
    &.archive-warning {
        border-left: 10px solid $shrimp;
        background-color: rgba($shrimp, 0.05);

        * {
            color: $shrimp;
        }

        a {
            color: $shrimp;
            text-decoration-line: underline;

            &:hover {
                opacity: .85;
            }
        }
    }

    /* --- warning for dev version --- */
    &.dev-warning {
        border-left: 10px solid $shrimp;
        background: linear-gradient(90deg, rgba(251,78,78,.1) 0%, rgba(249,154,95,.1) 50%);

        * {
            color: $shrimp;
        }

        a {
            color: $shrimp;
            text-decoration-line: underline;

            &:hover {
                opacity: .85;
            }
        }
    }

    > * {
        margin: 0;
        padding: 8px 16px;
    }
}
