.edit-page-cta {
    font-weight: 400;
    font-size: 14px;
    color: $shrimp;
    text-align: right;
    margin-right: 32px;
    display: block;
    position: absolute;
    right: 0;
}
