// Disable sidenav animations for the initial render.
.starting.mat-drawer-transition .mat-drawer-content {
  transition: none;
}

aio-nav-menu {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  ul, a {
    padding: 0;
    margin: 0;
  }

  &:first-child {
    margin-top: 16px;
  }

  aio-nav-item div a {
    padding-left: 6px;
  }
}

mat-sidenav.mat-sidenav.sidenav {
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  padding: 0;
  min-width: 260px;
  background-color: $offwhite;
  box-shadow: 6px 0 6px rgba(0,0,0,0.10);

  &.collapsed {
    top: 56px;
  }
}

mat-sidenav-container.sidenav-container {
  min-height: 100%;
  height: auto !important;
  max-width: 100%;
  margin: 0;
  transform: none;

  &.has-floating-toc {
    max-width: 82%;
  }
}

mat-sidenav-container div.mat-sidenav-content {
  height: auto;
}

.vertical-menu-item {
  box-sizing: border-box;
  color: $darkgray;
  cursor: pointer;
  display: block;
  max-width: 260px;
  overflow-wrap: break-word;
  padding-top: 4px;
  padding-bottom: 4px;
  text-decoration: none;
  text-align: left;
  width: 93%;
  word-wrap: break-word;

  &:hover {
    background-color: $lightgray;
    color: $shrimp;
    text-shadow: 0 0 5px #ffffff;
  }

  //icons _within_ nav
  .mat-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 4px;
  }
}

.vertical-menu-item.selected {
  color: $shrimp;
}

button.vertical-menu-item {
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;

  border: none;
  background-color: transparent;
  margin-right: 0;
  padding-left: 6px;
  padding-top: 8px;
  padding-bottom: 10px;
}

.heading {
  color: $darkgray;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
}

.heading-children.expanded {
  visibility: visible;
  opacity: 1;
  max-height: 4000px; // Arbitrary max-height. Can increase if needed. Must have measurement to transition height.
  transition: visibility 500ms, opacity 500ms, max-height 500ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.heading-children.collapsed {
  visibility: hidden;
  opacity: 0;
  max-height: 1px; // Must have measurement to transition height.
  transition: visibility 275ms, opacity 275ms, max-height 280ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.no-animations {
  .heading-children.expanded, .heading-children.collapsed {
    transition: none! important;
  }
}

.level-1 {
  font-family: $main-font;
  font-size: 14px;
  font-weight: 400;
  margin-left: 14px;
  transition: background-color 0.2s;
  text-transform: uppercase;
}

.level-2 {
  color: $mediumgray;
  font-family: $main-font;
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
  text-transform: none;
}

.level-3 {
  color: $mediumgray;
  font-family: $main-font;
  font-size: 14px;
  margin-left: 10px;
}

.level-1.expanded .mat-icon, .level-2.expanded .mat-icon {
  @include rotate(90deg);
}

.level-1:not(.expanded) .mat-icon, .level-2:not(.expanded) .mat-icon {
  @include rotate(0deg);
}

aio-nav-menu.top-menu {
  padding: 24px 0 0;

  aio-nav-item:last-child div {
    border-bottom: 1px solid rgba($mediumgray, 0.5);
  }

  aio-nav-item:first-child div {
    border-top: 1px solid rgba($mediumgray, 0.5);
  }
}

// Angular Version Selector
mat-sidenav .doc-version {
  padding: 8px;
  border-top: 1px solid $lightgray;

  select {
    outline: none;
    width: 100%;
    background: rgba($lightgray, 0.5);
    height: 32px;
    border: 1px solid $lightgray;
    color: $darkgray;

    option {
      font-family: $main-font;
      font-size: 14px;
    }
  }
}
