/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/

@mixin bp($point) {

  $bp-xsmall: "(min-width: 320px)";
  $bp-teeny: "(min-width: 480px)";
  $bp-tiny: "(min-width: 600px)";
  $bp-small: "(min-width: 650px)";
  $bp-medium: "(min-width: 800px)";
  $bp-big: "(min-width: 1000px)";

  @if $point == big {
    @media #{$bp-big} { @content; }
  }
  @else if $point == medium {
    @media #{$bp-medium} { @content; }
  }
  @else if $point == small {
    @media #{$bp-small}  { @content; }
  }
  @else if $point == tiny {
    @media #{$bp-tiny}  { @content; }
  }
  @else if $point == teeny {
    @media #{$bp-teeny}  { @content; }
  }
  @else if $point == xsmall {
    @media #{$bp-xsmall}  { @content; }
  }
}

@mixin rotate($degrees) {
  -moz-transform: rotate($degrees);
  -webkit-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  transform: rotate($degrees);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}

@mixin codeblock($bgcolor) {
    background-color: $bgcolor;
    border-radius: 5px;
    padding: 20px;
    margin: 0 auto; // was 24
    border: 0.5px solid $lightgray;
}

// INFO CARD SKELETON
@mixin card($height, $width) {
  height: $height;
  width: $width;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba($black, 0.24), 0 0 2px rgba($black, 0.12);
  box-sizing: border-box;
  transition: box-shadow .5s;

  &:hover {
    box-shadow: 0 8px 8px rgba($black, 0.24), 0 0 8px rgba($black, 0.12);
    text-decoration: none;
  }
}