nav#main-table-of-contents {
    width: 200px;
    height: 900px;
    position: fixed;
    right: 0;
    top: 50px;
    bottom: 100px;
    margin-left: 32px;
    background-color: $shrimp;
}
